@media (max-width: 575.98px) {
  .school-title-style {
    color: black;
    font-family: cursive;
    font-size: 15px;
    padding: 0px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .school-title-style {
    color: black;
    font-family: cursive;
    font-size: 13px;
    padding: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .school-title-style {
    color: black;
    font-family: cursive;
    font-size: 13px;
    padding: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .school-title-style {
    color: black;
    font-family: cursive;
    font-size: 13px;
    padding: 0px;
    margin-top: 10px;
    padding: auto;
  }
}
@media (min-width: 1200px) {
}

.btn .user-photo {
  height: 50px;
}

.school-sub-title-style {
  color: black;
  font-family: cursive;
  font-size: 13px;
}

.navbar-fixed-top {
  top: 0;
}
