.demo-wrapper {
  border: 1px solid black;
}

.demo-editor {
  height: 110px !important;
}

a.btn-add.btn.btn-secondary {
  color: #fff;
  background-color: #007bff;
  border-color: #6c757d;
}

a :hover {
  color: black;
  
}
a  {
  color: black;
  
}