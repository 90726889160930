/* a {
  color: #f30000;
  padding-left: 10px  !important;
  padding: 15px !important;
  text-decoration: none;
  background-color: transparent;
}

a:hover{
  color: #ff0000;
  background-color: #f1f1f1;
} */


/* .navbar-nav .nav-link.active {
  position: relative;
  color: #a90101;
  border-left: 3px solid #a90101;
}

.navbar-nav .nav-link.ac {
  color: #5a5a5a;
  padding-left: 10px  !important;
  padding: 15px !important;
  text-decoration: none;
  background-color: transparent;
} */


.sidebar-nav .metismenu>li a {
  border-left: 5px solid transparent;
}

.theme-orange .sidebar-nav .metismenu>li.active>a {
  border-left-color: #f68c1f;
}