.section {
  border: 0.5px gray solid;
  width: 150px;
  margin-bottom: 10px;
}

.borderless td,
.borderless th {
  border: none;
}

/* .importButton {
  display: none;
} */
