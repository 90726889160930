.c-style {
  border-radius: 0.55rem;
  background: #fff;
  transition: 0.5s;
  border: 0;
  margin-bottom: 10px;
  position: relative;
  height: 80vh;
  overflow: auto;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.h-style {
  background-color: #fff;
  border: none;
  padding: 20px 20px 0 20px;
}

.c-title-style {
  color: black;
  font-size: 23px;
  padding: 0px;
}

.title-style {
  color: black;
  font-size: 23px;
  padding: 23px;
}

.btn-style {
  padding: 0px;
}

.error-style {
  display: flex;
  justify-content: center;
  height: 45vh;
  align-items: center;
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .t-style {
    display: block;
    overflow-x: auto;
  }
}

@media (min-width: 1376px) and (max-width: 1710px) {
  .t-style {
    display: block;
    overflow-x: auto;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #28a745 !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28a745 !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.required {
  color: red;
  margin-left: 3px;
}

.table-header-wrapper {
  background-color: rgb(249, 250, 252);
  text-wrap: nowrap;
}

.pagination-wrapper {
  display: flex;
  justify-content: right;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
