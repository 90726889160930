.c-style{
  border-radius: .55rem;
  background: #fff;
  transition: .5s;
  border: 0;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
}

.h-style{
  background-color: #fff;
  border: none;
}

.c-title-style{
    color: black;
    font-size: 23px;
    padding: 7px;
}

.title-style{
  color: black;
  font-size: 23px;
  padding: 23px;
}

.btn-style{
  padding: 5px;
}

.error-style{
    display: flex;
    justify-content: center;
}

.btn-style{
  padding: 20px;
}

.btn-style-1{
  padding: 5px;
  margin-left: 10px;
}